 // for robi career
// export const environment = {
//   production: true,
//   appName: 'Recruitment',
//   apiUrl: 'https://backend.robicareer.com/api/v1',

//   // apiUrl: 'http://18.141.190.99:8009/api/v1',
//   // apiUrl: 'http://career-backend.linkvisionsoftware.com/api/v1',
//     baseUrl: 'https://backend.robicareer.com/',
//   appUrl: 'https://www.robicareer.com',
//   pac_id: 1,
//   pac_client_secret: 'mJC23h7hmegCo82Sd1Oneq1oHYWXNrph9KiVoGf2',
//   pgc_id: 2,
//   pgc_client_secret: '1IoBLM19GuoIcwPazRi6E8wUdA8KHUFU5A9dW1Io',
// };

//  for local stage
export const environment = {
  production: true,
  appName: 'Recruitment',
  apiUrl: 'https://recruitment-back.linkvisionsoftware.com/api/v1',
  appUrl: 'https://recruitment.linkvisionsoftware.com',
  // baseUrl: 'http://18.141.190.99:8009/',
  baseUrl: 'https://recruitment-back.linkvisionsoftware.com/',
  pac_id: 1,
  pac_client_secret: '02uJCiYIYSaGnVwIdIVLZULwPCtyIqXZWag6yNLE',
  pgc_id: 2,
  pgc_client_secret: 'tZXz5x0ms11gysGB8rKSCuMxhr2U7bPkQltjUQM8',
};



 // for local stage
// export const environment = {
//   production: true,
//   appName: 'Recruitment',
//   apiUrl: 'http://18.141.190.99:8009/api/v1',
//   appUrl: 'https://www.robicareer.com',
//   baseUrl: 'http://18.141.190.99:8009/',
//   pac_id: 1,
//   pac_client_secret: 'mJC23h7hmegCo82Sd1Oneq1oHYWXNrph9KiVoGf2',
//   pgc_id: 2,
//   pgc_client_secret: 'fkv7uvCBoYJCNJgOjhbl5rHQSl03iNtLMUuQlMVc',
// };



// export const environment = {
//   production: true,
//   appName: 'Recruitment',
//   apiUrl: 'https://backend.robicareer.com/api/v1',
//   // apiUrl: 'http://career-backend.linkvisionsoftware.com/api/v1',
//   appUrl: 'https://www.robicareer.com',
//   baseUrl: 'https://backend.robicareer.com/',
//   pac_id: 1,
//   pac_client_secret: 'pRoKklY9nDpEFkUVk6oyOWMLIJWAQDdlZVidkYQ7',
//   pgc_id: 2,
//   pgc_client_secret: '1IoBLM19GuoIcwPazRi6E8wUdA8KHUFU5A9dW1Io',
// };
